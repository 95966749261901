import React from 'react';
import { AiFillMail } from 'react-icons/ai';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

function CoreCards({ data }) {
    if (!data || data.length === 0) return null;

    return (
        <div className='flex flex-wrap justify-center m-1'>
            {data.map((core, index) => (
                <div key={index} className='text-white bg-iemctcard h-auto w-auto max-w-lg max-h-[30vh] rounded-xl p-1 hover:bg-iemctcardhov hover:-translate-y-5 duration-200 m-1 px-2'>
                    <div className='flex sm:px-2 p-0 content-center align-middle items-center justify-between'>
                        <div>
                            <div className='p-2 text-2xl font-bold'>
                                {core.name}
                            </div>
                            <div className='text-iemctwhite'>
                                <ul className="sci flex justify-between">
                                    {core.gmail ? (
                                        <li>
                                            <button type="button" alt=''>
                                                <a href={`mailto:${core.gmail}`} target="_blank" rel="noreferrer">
                                                    <AiFillMail className='mt-1' />
                                                </a>
                                            </button>
                                        </li>
                                    ) : null}

                                    {core.linkedIn ? (
                                        <li>
                                            <button type="button" alt=''>
                                                <a href={core.linkedIn} target="_blank" rel="noreferrer">
                                                    <FontAwesomeIcon icon={faLinkedin} />
                                                </a>
                                            </button>
                                        </li>
                                    ) : null}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default CoreCards;
