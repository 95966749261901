import React from 'react'
import fa from './assets/Fa.jpg'
import pres from './assets/presd.jpg'
import {motion} from 'framer-motion'

function Addresses() {
  return (
    <div className='p-5 bg-secondback text-iemctwhite '>
      <div className={` overflow-auto container w-[100%] h-auto flex-grow border-8 border-iemctwhite p-5 text-center md:text-8xl text-4xl pt-5 mb-5 font-raleway rounded-md font-semibold text-iemctwhite bg-backcol`}>
       Our Leaders
    </div>

    <div className='lg:mt-14 mt-0 mb-2 font-montserrat  sm:w-3/4 text-justify sm:text-lg text-md'>
      Hear from our Faculty Advisor and our Club President: 
      </div>

    {/* Faculty Advisor */}
      <motion.div
    initial={{x:200,opacity:0}}
    whileInView={{x:0,opacity:1,transition:{duration:1}}}
    viewport={{ once: true }}  className='p-2 flex flex-col-reverse lg:flex-row text-iemctwhite justify-center'>

      <div className='lg:mt-14 mt-0 mb-2 font-montserrat  sm:w-[80%]  sm:text-lg text-sm'>
      IE Mechatronics is a student technical club registered under Institute of Engineers (India).
      <span className='font-semibold inline-block text-iemctredhov'> This is not just a club; it's a community of like-minded individuals who are passionate about robotics and automation.</span> We are committed to fostering personal and professional growth, creating memorable experiences, and making a positive impact on our campus and beyond. As a team our work focuses on social innovations through continuous learning and peer support. Some of our prime activities include school visits for upcoming technology discussions, workshops like Looking into the future, group projects etc. We would be delighted to have you on board for collaborative research, whether you are a current member, or someone interested in joining; we look forward to welcoming you to our club.<br/>
      <span className='font-semibold inline-block text-iemctredhov'>-Professor Ishawar Bhiradi, Mechatronics Department</span> 
      
      </div>
        <div  className=' img border-iemctredhov duration-200 mx-auto'>
          <img src={fa} alt="Prof.Ishwar" className='w-auto h-auto rounded-md' />
        </div>
      </motion.div>

      {/* President */}

      <motion.div
    initial={{x:-200,opacity:0}}
    whileInView={{x:0,opacity:1,transition:{duration:1}}}
    viewport={{ once: true }}  className='p-2 flex flex-col lg:flex-row text-iemctwhite justify-center'>
       <div  className=' img border-iemctredhov duration-200 mx-auto'>
          <img src={pres} alt="President" className='w-auto h-auto rounded-md' />
        </div>
      <div className='lg:mt-14 mt-0 mb-2 font-montserrat  sm:w-[80%]  sm:text-lg text-sm '>
      At IE Mechatronics, we are a dynamic and passionate community of engineering enthusiasts driven by the spirit of innovation and collaboration. Our club focuses on various aspects of engineering, including Robotics, Mechatronics, Automation, and more, providing a platform for students to work on real-world projects, hone their technical skills, and participate in national and international events. <span className='font-semibold inline-block text-iemctredhov'>Whether you're interested in mechanical systems, electronics, AI, or robotics, IE Mechatronics offers a diverse set of activities and opportunities for everyone. As a club, we organize exciting events, workshops, and seminars to promote learning and growth in emerging technologies.</span> <span className='font-semibold inline-block text-iemctredhov'> If you're passionate about engineering and eager to make an impact, we invite you to join us and be a part of our journey toward shaping the future of technology. Explore, innovate, and grow with IEMCT!</span>Looking forward to welcoming new members and creating a strong future together!
      <br/>
      Connecting the dots to engineer the future.<br/>
      <span className='font-semibold inline-block text-iemctredhov'>-Alakhchandra Ladha, President-IE Mechatronics
</span> 
      </div>
       
      </motion.div>



    </div>
  )
}

export default Addresses
