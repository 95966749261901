import React from 'react'
import '../master.css';
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';
import MemberCard from '../MemberCard';
import Membertext from '../Membertext';
import CoreCards from '../CoreCards';
import {motion} from 'framer-motion'
import axios from 'axios';
import Loading from '../Loading/Loading';


function Members() {
    const base_url='https://ap-south-1.aws.data.mongodb-api.com/app/iemct_data-ldrbc/endpoint/board'

    const [isLoading, setIsLoading] = React.useState(false);
    const [data, setData] = React.useState("");
   


    React.useEffect(() => {
        window.scrollTo({top:0,left:0});

        setIsLoading(true);
        axios.get(base_url).then((response) => {
            setData(response.data);
            setIsLoading(false);
          });
        }, [])

        const boardData=Object.values(data).filter((board)=>board.role.includes("board"))
        const webData=Object.values(data).filter((board)=>board.role.includes("website"))
        const CoreData=Object.values(data).filter((board)=>board.role.includes("CoreComm"))

        const ManCommData=Object.values(data).filter((board)=>board.role.includes("ManComm"))
        const WorkCommData=Object.values(data).filter((board)=>board.role.includes("WorkComm"))



  return (
    <motion.div className='p-5 pb-10 bg-secondback'
    initial={{width:0}}
    animate={{width:"100%"}}
    exit={{x:-window.innerWidth, transition:{duration:0.3}}}>
    
    <div className={`overflow-auto container w-[100%] h-auto flex-grow border-8 border-iemctwhite p-5 text-center md:text-8xl text-4xl pt-5 mb-5 font-raleway rounded-md font-semibold text-iemctwhite bg-backcol`}>
      The Team
    </div>
    {isLoading?<Loading/>:
     <>
{/* Board */}
    <div className='flex flex-row md:justify-center p-10'>
    <div className='md:text-7xl text-3xl text-gray-500 pt-10 pb-10 font-montserrat font-bold flex flex-col'>
    <div className='text-iemctwhite'>
     The Board
     </div>
     <div className='text-gray-200 '>
    <hr />
     </div>
     
     </div>
     <div className='text-5xl md:text-9xl text-gray-200 ml-5 md:mt-5 mt-12 font-numeric'>

     <CountUp end={boardData.length} redraw={true}>
        {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
            </VisibilitySensor>
        )}
    </CountUp>
    </div>
    </div>
    <motion.div
        initial={{y:200,opacity:0}}
        whileInView={{y:0,opacity:1,transition:{duration:1}}}
        viewport={{ once: true }}
       > 
    <MemberCard data={boardData}/>
    </motion.div>

{/* Website Team */}
    <div className='flex flex-row md:justify-center p-10'>
    <div className='md:text-7xl text-3xl text-gray-500 pt-10 pb-10 font-bold font-montserrat flex flex-col'>
    <div className='text-iemctwhite'>
     Website Team
     </div>
     <div className='text-gray-200 '>
    <hr />
     </div>
     </div>
     <div className='text-5xl md:text-9xl text-gray-200 ml-5 font-numeric md:mt-5 mt-12'>
     <CountUp end={webData.length} redraw={true}>
        {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
            </VisibilitySensor>
        )}
    </CountUp>
    </div>
    </div>
    <MemberCard data={webData}/>
{/* Core Committee */}
<div className='flex flex-row md:justify-center p-10'>
    <div className='md:text-7xl text-3xl text-gray-500 pt-10 pb-10 font-bold font-montserrat flex flex-col'>
    <div className='text-iemctwhite'>
     Core Commitee
     </div>
     <div className='text-gray-200 '>
    <hr />
     </div>
    
     </div>
     <div className='text-5xl md:text-9xl text-gray-200 ml-5 font-numeric md:mt-5 mt-12'>
     <CountUp end={CoreData.length} redraw={true}>
        {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
            </VisibilitySensor>
        )}
    </CountUp>
    </div>
    </div>
    <MemberCard data={CoreData}/>

{/* ManComm */}
<div className='flex flex-row md:justify-center p-10'>
    <div className='md:text-7xl text-3xl text-gray-500 pt-10 pb-10 font-bold font-montserrat flex flex-col'>
    <div className='text-iemctwhite'>
     Managing Commitee
     </div>
     <div className='text-gray-200 '>
    <hr />
     </div>
    
     </div>
     <div className='text-5xl md:text-9xl text-gray-200 ml-5 font-numeric md:mt-5 mt-12'>
     <CountUp end={ManCommData.length} redraw={true}>
        {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
            </VisibilitySensor>
        )}
    </CountUp>
    </div>
    </div>
    <CoreCards data={ManCommData}/>

{/* WorkComm */}
{WorkCommData.map((data)=>(
    <>
    <div className='flex flex-row md:justify-center p-10'>
    <div className='md:text-7xl text-3xl text-gray-500 pt-10 pb-10 font-bold font-montserrat flex flex-col'>
    <div className='text-iemctwhite'>
     Working Commitee
     </div>
     <div className='text-gray-200 '>
    <hr />
     </div>
     
     </div>
     <div className='text-5xl md:text-9xl text-gray-200 ml-5 font-numeric md:mt-5 mt-12'>
     <CountUp end={data.size} redraw={true}>
        {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
            </VisibilitySensor>
        )}
    </CountUp>
    </div>
    </div>
    <Membertext data={data.names} />
    </>
        ))}     
    </> }
      
    </motion.div>
  )
}

export default Members
