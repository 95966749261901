import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Heading from './assets/head2.png';
import React, { useState } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: "#151515", 
  opacity: "95%",
  p: 4,
};

export default function JoinForm() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [name, setName] = useState("");
  const [regno, setRegno] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [branch, setBranch] = useState("");
  const [subsystem1, setSubsystem1] = useState("");
  const [subsystem2, setSubsystem2] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate fields before submitting
    if (regno.length < 1 || name.length < 1 || email.length < 1 || phone.length < 1 || branch.length < 1 || subsystem1.length < 1 || subsystem2.length < 1) {
      setMessage("Please enter all information before submitting!");
      setTimeout(() => {
        setMessage("");
      }, 1200);
    } else if (!email.includes('@')) {
      setMessage("Email should have '@'");
      setTimeout(() => {
        setMessage("");
      }, 1200);
    } else {
      try {
        let res = await fetch("https://ap-south-1.aws.data.mongodb-api.com/app/iemct_data-ldrbc/endpoint/joinInfo", {
          method: "POST",
          body: JSON.stringify({
            time: new Date(),
            name: name,
            email: email,
            regno: regno,
            phone: phone,
            branch: branch,
            subsystem1: subsystem1,
            subsystem2: subsystem2,       
          }),
        });
        let resJson = await res.json();
        if (res.status === 200) {
          setName("");
          setEmail("");
          setPhone("");
          setBranch("");
          setSubsystem1("");
          setSubsystem2("");
          setMessage("Information Submitted");
          setTimeout(() => {
            setMessage("");
          }, 1200);
          setTimeout(() => {
            handleClose();
          }, 2000);
        } else {
          setMessage("Some error occurred");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div>
      <div className="md:text-xl block py-2 pl-3 pr-4 text-gray-300 rounded hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-50 md:p-0">
        <button className='border-2 border-gray-300 hover:border-gray-50 px-2 rounded-md' onClick={handleOpen}>Join</button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='border-2 border-iemctred shadow-md duration-200 sm:w-3/4 w-[75%] sm:h-auto h-[75%] overflow-auto rounded-md'>
          <div className='flex justify-center'>
            {/* logo */}
            <img src={Heading} alt="IEMCT_Logo" className='p-0 h-20 -my-4 pb-5 mb-auto pt-2' />
          </div>
          <div className='text-gray-300 font-custom text-3xl'>
            Become a Member!
          </div>
          <div className='text-gray-400 text-xl font-raleway'>
            Enter your details below and we will contact you soon.
          </div>

          <form>
            <div className='flex justify-center mt-2 p-2 flex-col sm:flex-row flex-wrap'>
              <label className='m-2 text-gray-200'>
                Name:
                <input type="text" name="name" className='m-2 bg-[#151515] border-2 border-gray-300 py-2 mb-2 mt-4 text-white-300 rounded-md' onChange={(e) => { setName(e.target.value); }} />
              </label>
              <label className='m-2 text-gray-200'>
                RegNo:
                <input type="text" name="regno" className='m-2 bg-[#151515] border-2 border-gray-300 py-2 mb-2 mt-4 text-white-300 rounded-md' onChange={(e) => { setRegno(e.target.value); }} />
              </label>
              <label className='m-2 text-gray-200'>
                Email:
                <input type="text" name="email" className='m-2 bg-[#151515] border-2 border-gray-300 py-2 mb-2 mt-4 text-white-300 rounded-md' onChange={(e) => { setEmail(e.target.value); }} />
              </label>
              <label className='m-2 text-gray-200'>
                Phone:
                <input type="text" name="phone" className='m-2 bg-[#151515] border-2 border-gray-300 py-2 mb-2 mt-4 text-white-300 rounded-md' onChange={(e) => { setPhone(e.target.value); }} />
              </label>

              <label className='m-2 text-gray-200'>
                Branch:
                <input type="text" name="branch" className='m-2 bg-[#151515] border-2 border-gray-300 py-2 mb-2 mt-4 text-white-300 rounded-md' onChange={(e) => { setBranch(e.target.value); }} />
              </label>

              <label className='m-2 text-gray-200'>
                Preferred Subsystem 1:
                <select name="subsystem1" className='m-2 bg-[#151515] border-2 border-gray-300 py-2 mb-2 mt-4 text-white-300 rounded-md' onChange={(e) => { setSubsystem1(e.target.value); }}>
                  <option value="">Select Subsystem</option>
                  <option value="AI&D">AI and Development</option>
                  <option value="Robotics">Robotics</option>
                  <option value="E&E">Electrical and Electronics</option>
                  <option value="Mechanical">Mechanical</option>
                  <option value="HRM">Human Resources and Management</option>
                  <option value="SM">Social Media</option>
                </select>
              </label>

              <label className='m-2 text-gray-200'>
                Preferred Subsystem 2:
                <select name="subsystem2" className='m-2 bg-[#151515] border-2 border-gray-300 py-2 mb-2 mt-4 text-white-300 rounded-md' onChange={(e) => { setSubsystem2(e.target.value); }}>
                  <option value="">Select Subsystem</option>
                  <option value="AI&D">AI and Development</option>
                  <option value="Robotics">Robotics</option>
                  <option value="E&E">Electrical and Electronics</option>
                  <option value="Mechanical">Mechanical</option>
                  <option value="HRM">Human Resources and Management</option>
                  <option value="SM">Social Media</option>
                  <option value="None">None</option>
                  
                </select>
              </label>
            </div>

            <div className='flex justify-center'>
              <button type="submit" className='bg-gradient-to-b px-10 mt-4 text-xl text-iemctred hover:text-iemctwhite py-2 border-2 border-iemctred mb-10 flex justify-end align-middle hover:bg-iemctred duration-150 hover:scale-105 rounded-md' onClick={handleSubmit}>
                Submit
              </button>
            </div>

            <div className="message flex justify-center text-iemctred text-xl my-8">
              {message ? <p>{message}</p> : null}
            </div>
          </form>

          <div className="flex flex-row justify-center">
            {/* mail */}
            <button className='p-2 hover:scale-110 duration-150'>
              <a href='mailto:Convener.iemechatronics@gmail.com' target='_blank'>
                <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#151515"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.272 6.365C2 6.9 2 7.6 2 9v6c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.092C3.9 19 4.6 19 6 19h12c1.4 0 2.1 0 2.635-.273a2.5 2.5 0 0 0 1.092-1.092C22 17.1 22 16.4 22 15V9c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.092-1.093C20.1 5 19.4 5 18 5H6c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 0 0-1.093 1.093zM4.647 7h14.706a.5.5 0 0 1 .278.916l-7.075 4.732a1 1 0 0 1-1.112 0L4.369 7.916A.5.5 0 0 1 4.647 7z" fill="#FFFFFF"></path></g></svg>
              </a>
            </button>

            {/* insta */}
            <button className='p-1 hover:scale-110 duration-150'>
              <a href="https://instagram.com/iemechatronics?igshid=YmMyMTA2M2Y=" target='_blank'>
                <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M3.06167 7.24464C3.10844 6.22264 3.26846 5.56351 3.48487 5.00402L3.48778 4.99629C3.70223 4.42695 4.03818 3.91119 4.47224 3.48489L4.47833 3.47891L4.48431 3.47282C4.91096 3.0382 5.42691 2.70258 5.99575 2.4887L6.00556 2.48495C6.56378 2.26786 7.22162 2.10843 8.24447 2.06167M3.06167 7.24464C3.0125 8.33659 2.99997 8.67508 2.99997 11.5063C2.99997 14.386 3.0125 14.7245 3.06167 15.8164M3.06167 7.24464C4.06399 7.39348 4.98644 7.72343 5.56959 8.2135L5.57251 8.21586C6.0164 8.63819 6.50786 9.19689 7.19181 9.27607L7.31916 9.27656L7.49871 9.25399C8.13062 9.14483 8.91785 8.74155 9.38317 8.22312L9.42077 8.15824L9.46017 8.12157L9.5488 7.96469C9.98153 7.49966 9.98847 6.9265 9.46529 6.33397C8.93597 5.80414 8.24816 5.71255 7.67384 6.09278C7.36633 6.33299 7.35244 6.77356 7.68227 7.13542C7.79132 7.32261 8.07877 7.40677 8.38749 7.46446L8.24467 7.41699C8.01117 7.36706 7.88889 7.52976 7.65649 7.64758L7.36485 7.81403L7.2996 7.9521M3.06167 7.24464C4.1727 7.09656 5.47491 7.03903 6.80348 7.0761M4.48431 3.47282L4.57653 3.53494L4.84699 3.81015M4.48431 3.47282C5.05409 3.059 5.71229 3.06506 6.12964 3.29099C6.5335 3.49873 6.86875 3.93497 7.02859 4.52924M7.02859 4.52924C7.1321 5.13747 7.19001 5.72247 7.19951 6.20602M7.02859 4.52924L6.73167 4.71773L6.87513 4.54435L6.20862 4.73969L6.50513 4.77877L7.02859 4.52924ZM15.6995 7.03199C14.3432 7.20846 12.8585 7.48046 11.651 8.0907C10.758 8.56335 9.71672 9.6095 9.61699 10.6302C9.61402 10.6734 9.61523 10.7286 9.62033 10.7598M15.6995 7.03199C14.4783 7.0665 13.5166 7.08928 12.4969 7.467M15.6995 7.03199C16.0306 7.50604 16.2466 7.91797 16.6793 8.30925M15.6995 7.03199C15.0267 6.6641 14.4122 6.48601 13.8104 6.69696" stroke="#FFFFFF" stroke-width="1.5"></path></g></svg>
              </a>
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
